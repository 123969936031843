import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Manipulation } from "swiper";
import { FairCard } from "./FairCard";
import { AppContext } from "..//../ContextAPI/AppProvider";
import { baseApiURL, fairCount, lottieOption } from "../../Helpers/Constant";
import { checkSwiperPrevNextArrows } from "../../Helpers/Helper";
import { FairSliderSkeleton } from "../../components/Skeletons/Fairs/FairSliderSkeleton";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi2";

export const Fair = () => {
  let navigate = useNavigate();
  const [fairs, setFairs] = useState([]);
  const { dirimart, translation, setDirimartData } = useContext(AppContext);
  const [swiperRef, setSwiperRef] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [maxActiveIndex, setMaxActiveIndex] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [totalFairCount, setTotalFairCount] = useState(1);
  const [currentPage, setcurrentPage] = useState(1);
  const [blockUi, setBlockUi] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  SwiperCore.use([Pagination, Navigation, Manipulation]);

  const _getFairs = async (count) => {
    const getFormDataUrl = baseApiURL + "/fairs/getPublishedFairs";
    const data = {
      langId: dirimart.langId,
      recordCount: count + 2,
      pageNumber: currentPage,
    };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data.publishedFairs))
        .catch((e) => reject(e.data.ExceptionMessage));
    });
  };
  const _getFairMaxPage = () => {
    const getFormDataUrl = baseApiURL + "/fairs/getFairTotalCount";
    const data = { langId: dirimart.langId };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.data.ExceptionMessage));
    });
  };
  const _slideChange = (e) => {
    if (e.swipeDirection === "next") {
      setcurrentPage((prevPage) => prevPage + 1);
    } else if (e.swipeDirection === "prev") {
      setcurrentPage((prevPage) => Math.max(1, prevPage - 1));
    }
  };
  useEffect(() => {
    if (isInitialLoad) return;

    _getFairs(isMobile ? 1 : fairCount).then((response) => {
      // Mevcut fairs'a yeni response'u ekle, aynı kayıtları tekrarlamamak için
      setFairs((prevFairs) => {
        const uniqueFairs = [...prevFairs, ...response].filter(
          (fair, index, self) =>
            index === self.findIndex((t) => t.fairId === fair.fairId)
        );
        return uniqueFairs;
      });

      setBlockUi(false);
      setIsLoading(false);
    });
  }, [currentPage]);
  useEffect(() => {}, [swiperRef]);
  useEffect(() => {
    setBlockUi(true);
    let dirimartcopy = { ...dirimart };
    dirimartcopy.langButtonDisabled = true;
    setDirimartData(dirimartcopy);

    _getFairs(isMobile ? 1 : fairCount).then((response) => {
      setFairs(response);

      dirimartcopy.langButtonDisabled = false;
      setDirimartData(dirimartcopy);
    });
    _getFairMaxPage().then((response) => {
      setTotalFairCount(response);
      let maxPage = Math.ceil(response / fairCount);
      setMaxPageNumber(maxPage);
      setBlockUi(false);
      setIsLoading(false);
    });
  }, [dirimart.langId]);
  useEffect(() => {
    setIsInitialLoad(false);
    _getFairs(isMobile ? 1 : fairCount).then((response) => {
      setFairs(response);
    });
    _getFairMaxPage().then((response) => {
      setTotalFairCount(response);
      let maxPage = Math.ceil(response / fairCount);
      setMaxPageNumber(maxPage);
      setIsLoading(false);
      setBlockUi(false);
    });
  }, []);

  const handleNextClick = () => {
    if (swiperRef) {
      swiperRef.slideNext();
      setcurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (swiperRef) {
      swiperRef.slidePrev();
      setcurrentPage((prevPage) => Math.max(1, prevPage - 1));
    }
  };

  return (
    <>
      <div className="fairsContainer">
        <div className="fairsHeader">
          <h2>{translation.Fair.Title}</h2>
          <div className="fairsRightSide">
            <div className={`fairsArrow`} onClick={handlePrevClick}>
              <HiArrowLeft />
            </div>
            <div className={`fairsArrow`} onClick={handleNextClick}>
              <HiArrowRight />
            </div>
          </div>
        </div>

        {isLoading && <FairSliderSkeleton />}
        {!isLoading && (
          <>
            <div className="fairs">
              <Swiper
                spaceBetween={10}
                onSwiper={(swiper) => {
                  setSwiperRef(swiper);
                }}
                onSlideChange={(e) => {
                  _slideChange(e);
                }}
                breakpoints={{
                  640: { slidesPerView: 1 },
                  768: { slidesPerView: totalFairCount > 1 ? 1.4 : 1 },
                  1366: { slidesPerView: totalFairCount > 1 ? 2.4 : 1 },
                  1920: { slidesPerView: totalFairCount > 1 ? 2.4 : 1 },
                }}
              >
                {fairs.slice(0, maxPageNumber * fairCount).map((item) => (
                  <SwiperSlide key={item.fairId}>
                    <FairCard item={item} translation={translation} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <button
              className="btnWhite"
              style={{ marginTop: "40px" }}
              onClick={() => navigate("/Fairs")}
            >
              {translation.Common?.seemoreFairs}
            </button>
          </>
        )}
      </div>
    </>
  );
};
