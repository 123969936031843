import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../ContextAPI/AppProvider";
import { isMobile } from "react-device-detect";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { baseApiURL, lottieOption, newsLocation } from "../Helpers/Constant";
import { NewsCardComponent } from "./NewsCardComponent";
import { HiArrowRight, HiArrowLeft, HiArrowUpRight } from "react-icons/hi2";
import {
  checkSwiperPrevNextArrows,
  enableLangButton,
  disableLangButton,
} from "../Helpers/Helper";
import { useNavigate } from "react-router-dom";
import SwiperCore, { Pagination, Navigation, Manipulation } from "swiper";
import { NewsSliderSkeleton } from "./Skeletons/News/NewsSliderSkeleton";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-block-ui/style.css";

export const NewsComponent = (props) => {
  const navigate = useNavigate();
  const { dirimart, translation, setDirimartData } = useContext(AppContext);
  const [swiper, setSwiper] = useState(null);
  const [news, setNews] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [newsMaxPageCount, setNewsMaxPageCount] = useState(0);
  const [showMore, setShowMore] = useState(
    currentPage >= newsMaxPageCount ? false : true
  );
  const [swiperArrows, setSwiperArrows] = useState({
    prev: false,
    next: showMore,
  });
  const [blockUiNews, setblockUiNews] = useState(true);
  const [isInitialNewsLoad, setIsIntialNewsLoad] = useState(true);
  const [loadedPage, setLoadedPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewsLoaded, setIsNewsLoaded] = useState(false);

  SwiperCore.use([Pagination, Navigation, Manipulation]);

  const _nextSlide = async () => {
    let nextPageNumber = currentPage + 1;
    if (nextPageNumber > loadedPage) {
      if (nextPageNumber <= newsMaxPageCount) {
        setLoadedPage(nextPageNumber);
        setcurrentPage(nextPageNumber);
      } else {
        swiper.slideNext();
        _setSliderArrows();
      }
    } else {
      setcurrentPage(nextPageNumber);
      swiper.slideNext();
      _setSliderArrows();
    }
  };
  const _prevSlide = () => {
    setcurrentPage(currentPage - 1);
    swiper.slidePrev();
    _setSliderArrows();
  };

  const _setSliderArrows = () => {
    if (swiper === null || swiper === undefined) return;
    var arrows = checkSwiperPrevNextArrows(swiper);
    if (showMore) {
      arrows.next = true;
    }
    setSwiperArrows(arrows);
  };
  const _getNews = async () => {
    const getNewsUrl = baseApiURL + props.getNewsUrl;
    const data = {
      langId: dirimart.langId,
      Id: props.Id,
      recordCount: props.recordCount + 1,
      pageNumber: currentPage,
      type: props.type,
    };
    var config = {
      method: "post",
      url: getNewsUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getNewsTotalCount = async () => {
    const getNewsTotalCountUrl = baseApiURL + props.getNewsTotalCountUrl;
    const data = { Id: props.Id, type: props.type, langId: dirimart.langId };
    var config = {
      method: "post",
      url: getNewsTotalCountUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  useEffect(() => {
    _getNews()
      .then((response) => {
        setNews(response.news);
        setblockUiNews(false);
        if (response.news.length > 0) setIsNewsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
    _getNewsTotalCount().then((response) => {
      let maxPageCount = Math.ceil(response / (props.recordCount + 1));
      setNewsMaxPageCount(maxPageCount);
      setTotalCount(response);

      if (currentPage < maxPageCount - 1) {
        setShowMore(true);
      }
      setSwiperArrows({ prev: false, next: true });
      setIsLoading(false);
    });
    setIsInitialLoad(false);
  }, []);
  useEffect(() => {
    if (!isInitialLoad) if (swiper !== undefined) _setSliderArrows();
  }, [newsMaxPageCount]);

  useEffect(() => {
    if (isInitialLoad) {
      return;
    }

    setblockUiNews(true);
    _getNews()
      .then((response) => {
        if (loadedPage !== 1) setNews(news.concat(response.news));
        else setNews(response.news);
        setblockUiNews(false);
        if (props.recordCount === newsMaxPageCount) {
          setShowMore(true);
        }
        if (currentPage === newsMaxPageCount) {
          setShowMore(false);
        }
        if (swiper !== undefined) {
          swiper.slideNext();
          _setSliderArrows();
        }
      })
      .catch((error) => console.log(error));
  }, [loadedPage]);
  useEffect(() => {
    if (isInitialLoad) {
      return;
    }
    if (isInitialNewsLoad) {
      setIsIntialNewsLoad(false);
      return;
    }
    if (swiper !== null && swiper.destroyed === false) {
      if (news.length > props.recordCount)
        swiper.slideTo(swiper.activeIndex + 1);
      var arrows = checkSwiperPrevNextArrows(swiper);
      if (showMore) {
        arrows.next = true;
      }

      setSwiperArrows(arrows);
    }
  }, [news]);
  useEffect(() => {
    setcurrentPage(1);
    if (isInitialLoad) {
      return;
    }
    if (loadedPage !== 1) {
      setLoadedPage(1);

      return;
    }

    setDirimartData(disableLangButton(dirimart));
    setblockUiNews(true);
    _getNews()
      .then((response) => {
        setNews(response.news);

        if (props.recordCount === newsMaxPageCount) {
          setShowMore(true);
        }
        if (currentPage === newsMaxPageCount) {
          setShowMore(false);
        }
        if (swiper !== undefined) {
          _setSliderArrows();
        }
        setblockUiNews(false);
        props.setIsNewsLoading && props.setIsNewsLoading(false);
        setIsLoading(false);
        setDirimartData(enableLangButton(dirimart));
      })
      .catch((error) => console.log(error));
    _getNewsTotalCount().then((response) => {
      let maxPageCount = Math.ceil(response / (props.recordCount + 1));
      setNewsMaxPageCount(maxPageCount);
      setTotalCount(response);
      if (currentPage < maxPageCount) {
        setShowMore(true);
      }
    });
  }, [dirimart.langId]);
  return (
    <>
      {isNewsLoaded && (
        <div
          id="newsContainer"
          className="newsContainer artistDetail"
          style={props.style}
        >
          <div className="newsHeader">
            {props.showDefaultTitle == true && (
              <h2>{translation.News.Title}</h2>
            )}
            {props.title && <h2 style={props.titleStyle}>{props.title}</h2>}

            <div className="newsRightSide">
              {props.customRedirectButton && (
                <button
                  onClick={() => navigate(`${props.redirectUrl}`)}
                  className="btnWhite"
                >
                  {props.redirectText}
                  <HiArrowUpRight />
                </button>
              )}
              {props.customRedirectButton === undefined ||
                props.customRedirectButton === null ||
                (props.customRedirectButton === false && (
                  <>
                    {props.allNewsTitle &&
                      (props.location === undefined ||
                        props.location === newsLocation.indetail) && (
                        <button
                          onClick={() => navigate(`/News/`)}
                          className="btnWhite"
                        >
                          {props.allNewsTitle}
                          <HiArrowUpRight />
                        </button>
                      )}

                    {props.location === newsLocation.mainpage && (
                      <button
                        onClick={() => navigate(`/News`)}
                        className="btnYellow"
                      >
                        {translation.News.all}
                      </button>
                    )}
                  </>
                ))}

              <div
                className={
                  "newsArrow " + (swiperArrows.prev === false ? "disabled" : "")
                }
                style={props.arrowStyles}
                onClick={() => _prevSlide()}
              >
                <HiArrowLeft />
              </div>
              <div
                className={
                  "newsArrow " + (swiperArrows.next === false ? "disabled" : "")
                }
                style={props.arrowStyles}
                onClick={() => _nextSlide()}
              >
                <HiArrowRight />
              </div>
            </div>
          </div>
          <>
            {isLoading && <NewsSliderSkeleton></NewsSliderSkeleton>}
            {news[0] && totalCount && (
              <div className="news artistsNews">
                <Swiper
                  onSlideChange={() => _setSliderArrows()}
                  modules={[Autoplay, Pagination, Navigation]}
                  navigation={false}
                  pagination={false}
                  spaceBetween={30}
                  autoHeight={false}
                  // slidesPerView={isMobile ? 1 : news.length <= props.recordCount ? news.length : props.recordCount + 0.4}
                  breakpoints={{
                    640: {
                      // width: 640,
                      slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 2,
                    },
                    1366: {
                      slidesPerView:
                        totalCount === 1
                          ? 3
                          : totalCount <= props.recordCount
                          ? totalCount - 1
                          : props.recordCount,
                    },
                  }}
                  onSwiper={(swiper) => setSwiper(swiper)}
                >
                  {news.map((element, index) => {
                    return (
                      <SwiperSlide key={index}>
                        {props.location === newsLocation.mainpage && (
                          <>
                            <>
                              {index % 2 === 0 && (
                                <NewsCardComponent
                                  reverse={true}
                                  news={element}
                                />
                              )}
                            </>
                            <>
                              {index % 2 !== 0 && (
                                <NewsCardComponent news={element} />
                              )}
                            </>
                          </>
                        )}
                        {props.location === newsLocation.indetail ||
                          (props.location == undefined && (
                            <NewsCardComponent news={element} />
                          ))}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            )}
          </>
        </div>
      )}
    </>
  );
};
